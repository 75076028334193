import GenericForm from "@/forms/GenericForm";
import ParksOnTheAirForm from "@/forms/ParksOnTheAirForm";
import SotaForm from "@/forms/SotaTemplate";
function FormFactory(template: string) {
  switch (template) {
    case "POTA":
      return ParksOnTheAirForm;
    case "GENERIC":
      return GenericForm;
    case "SOTA":
      return SotaForm;
    default:
      return GenericForm;
  }
}
export default FormFactory;

// Usage
// const form = FormFactory("POTA");

