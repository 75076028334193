import PotaParkJsonRepo from '@/repos/PotaParkJsonRepo';
import { type PotaParkType } from "@/types";
import { potaParksLogger } from "@/utils/loggers";
import { useOnline } from '@vueuse/core'
import HamrsApiService from '@/services/HamrsApiService';

const online = useOnline();

const PotaParkService = {
  async fetchById(parkId: string): Promise<PotaParkType> {
    try {
      potaParksLogger(`Fetching park with ID: ${parkId}`);
      let park: PotaParkType;
      if (online.value) {
        potaParksLogger('Online: Fetching from API');
        park = await HamrsApiService.fetchPark(parkId);
      } else {
        potaParksLogger('Offline: Fetching from local JSON');
        park = PotaParkJsonRepo.fetchById(parkId);
      }
      potaParksLogger('Park fetched successfully');
      return park;
    } catch (error) {
      potaParksLogger('Error fetching park', error);
      throw error;
    }
  }
  // async create(park: PotaParkType): Promise<PotaParkType> {
  //   potaParksLogger("Creating park", park);
  //   return await potaParkDb.create({ _id: park.reference, ...park });
  // },

  // async getNewParks(): Promise<PotaParkType[]> {
  //   const hamrsApi = new HamrsApService();
  //   let newParks: PotaParkType[];
  //   try {
  //     potaParksLogger("Fetching New Parks");
  //     newParks = await hamrsApi.fetchParks(meta.lastUpdated);
  //     potaParksLogger("New Parks", newParks.length);
  //   } catch (error) {
  //     console.error("Failed to get new parks", error);
  //     throw error;
  //   }
  //   await newParks.slice(0, 25).forEach(async (park) => {
  //     await this.create(park);
  //   })
  //   return newParks
  // }
};

export default PotaParkService;