export default [
  {
    $cmp: "Row",
    props: {
      columns: 12,
    },
    children: [
      {
        $cmp: "Column",
        props: {
          span: 12,
          spanSm: 8
        },
        children: [
          {
            $cmp: "Container",
            children: [
              {
                $cmp: "Row",
                props: {
                  columns: 4,
                },
                children: [
                  {
                    $cmp: "TheirCall",
                    props: {
                      class: "col-span-2",
                    },
                  },
                  { $cmp: "RstSent" },
                  { $cmp: "RstReceived" },
                ],
              },
              {
                $cmp: "Row",
                props: {
                  columns: 2,
                },
                children: [{ $cmp: "TheirPark" }, { $cmp: "Comments" }],
              },
              {
                $cmp: "Row",
                props: {
                  columns: 2,
                },
                children: [
                  { $cmp: "Time" },
                  { $cmp: "Date" },
                ],
              },
              {
                $cmp: "Row",
                props: {
                  columns: 2,
                },
                children: [
                  {
                    $cmp: "Row",
                    props: {
                      columns: 2,
                    },
                    children: [
                      {
                        $cmp: "TheirName",
                      },
                      {
                        $cmp: "TheirCity",
                      },
                    ],
                  },
                  {
                    $cmp: "Row",
                    props: {
                      columns: 3,
                    },
                    children: [
                      {
                        $cmp: "TheirState",
                      },
                      {
                        $cmp: "TheirCounty",
                        props: {
                          label: "County",
                        }
                      },
                      {
                        $cmp: "TheirGrid",
                        props: {
                          label: "Grid",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                $el: "div",
                attrs: {
                  class: "flex justify-end mt-6",
                },
                children: [{ $cmp: "ClearButton" }, { $cmp: "SaveButton" }],
              },
            ],
          },
        ],
      },
      {
        $cmp: "Column",
        props: {
          span: 12,
          spanSm: 4
        },
        children: [
          {
            $cmp: "Container",
            children: [
              {
                $cmp: "Row",
                props: {
                  columns: 2,
                },
                children: [{ $cmp: "Frequency" }, { $cmp: "BandSelect" }],
              },
              {
                $cmp: "Row",
                props: {
                  columns: 2,
                },
                children: [{ $cmp: "TxPower" }, { $cmp: "ModeSelect" }],
              },
              {
                $cmp: "Row",
                props: {
                  columns: 2,
                },
                children: [{ $cmp: "MyPark" }, { $cmp: "MyCall" }],
              },
              {
                $cmp: "Row",
                props: {
                  columns: 2,
                },
                children: [
                  { $cmp: "MyGrid" },
                  {
                    $cmp: "TextField",
                    props: {
                      label: "Club Call",
                      field: "stationCallsign",
                    },
                  },
                ],
              },
              {
                $cmp: "Row",
                props: {
                  columns: 2,
                },
                children: [
                  {
                    $cmp: "TextField",
                    props: {
                      label: "My County",
                      field: "myCnty",
                      testId: "logger-inputs__my-county",
                    },
                  },
                  {
                    $cmp: "TextField",
                    props: {
                      label: "My State",
                      field: "myState",
                      testId: "logger-inputs__my-state",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
