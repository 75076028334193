import SotaSchema from "@/formkitSchemas/SotaSchema";
import type { StickyFieldsType } from "@/types/StickyFieldsType";

const SotaForm = {
  schema: SotaSchema,
  stickyFields: [
    "freq",
    "band",
    "mySotaRef",
    "myGridsquare",
    "mode",
    "txPwr",
    "myCnty",
    "myState",
    "operator",
    "stationCallsign",
  ] as StickyFieldsType,
};

export default SotaForm;
