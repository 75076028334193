import { rigControlLogger } from "@/utils/loggers";
import { BAND_RANGES } from "@/constants";
import { type BandType } from "@/types";
import type { FlrigModes, CommonModes, SsbModes } from "@/types";


const SSB_MODE_BY_BAND: Record<BandType, SsbModes> = {
  "160m": "LSB",
  "80m": "LSB",
  "60m": "USB",
  "40m": "LSB",
  "30m": "USB",
  "20m": "USB",
  "17m": "USB",
  "15m": "USB",
  "12m": "USB",
  "10m": "USB",
  "6m": "USB",
  "2m": "USB",
};
export interface FlrigResponse<T> {
  success: boolean;
  value?: T;
  error?: string;
  errorCode?: 'NO_RADIO' | 'INVALID_VALUE' | 'CONNECTION_ERROR' | 'PARSE_ERROR' | 'MISSING_ARGUMENTS';
}
export interface FlrigState {
  frequency: number;
  mode?: FlrigModes;
  power: number;
}
export const BandHelper = {

  getBandForFrequency(frequency: number): string | null {
    const band = BAND_RANGES.find(
      band => frequency >= band.lower * 1000 && frequency <= band.upper * 1000
    );
    return band?.name || null;
  },

  getPreferredSsbMode(band: BandType): 'USB' | 'LSB' {
    return SSB_MODE_BY_BAND[band] || 'USB';
  }
}

export function mapFlrigModeToCommonMode(mode: string): string {
  const modeMapping = {
    'USB': 'SSB',
    'LSB': 'SSB',
    'CW-U': 'CW',
    'CW-L': 'CW',
    'CW-R': 'CW',
    'CW': 'CW',
    'DATA-L': 'FT8',
    'DATA-U': 'FT8',
    'USB-D': 'FT8',
    'USB-D2': 'FT8',
    'FM': 'FM',
    'FM-D': 'FM',
    'FM-N': 'FM',
    'AM': 'AM',
    'RTTY': 'RTTY',
  };

  return modeMapping[mode as keyof typeof modeMapping] || mode;
}

export class FlrigService {
  private host: string;
  private port: string;
  public availableModes: FlrigModes[]
  public currentState: FlrigState

  constructor(host: string, port: string) {
    this.host = host;
    this.port = port;
    this.availableModes = [];
    this.currentState = {
      frequency: 0,
      power: 0
    };
  }

  public mapCommonModeToAvailableModes(commonMode: CommonModes, availableModes: FlrigModes[], band?: BandType): FlrigModes {
    // For CW modes
    if (commonMode === 'CW') {
      if (availableModes.includes('CW')) return 'CW';
      if (availableModes.includes('CW-L')) return 'CW-L';
      // if (availableModes.includes('CW-U')) return 'CW-U';
      if (availableModes.includes('CW-R')) return 'CW';
      throw new Error('No compatible CW mode available');
    }

    if (commonMode === 'SSB') {
      if (!band) {
        throw new Error(`Band is required to set SSB mode`);
      }
      const preferredMode = BandHelper.getPreferredSsbMode(band);
      if (availableModes.includes(preferredMode)) {
        return preferredMode;
      }
      throw new Error('No compatible SSB mode available');
    }

    // For FT8/FT4
    if (['FT8', 'FT4'].includes(commonMode)) {
      if (availableModes.includes('USB-D1')) return 'USB-D1';
      if (availableModes.includes('DATA-U')) return 'DATA-U';
      if (availableModes.includes('USB-D')) return 'USB-D';
      return 'USB'; // Fallback to USB if no digital modes available
    }

    // Direct mappings if available
    if (availableModes.includes(commonMode as FlrigModes)) {
      return commonMode as FlrigModes;
    }
    // Fallback to whatever was passed in
    return commonMode as FlrigModes;
  }


  private buildUrl(): string {
    const baseUrl = ['development'].includes(import.meta.env.MODE) ? '/flrig' : `http://${this.host}:${this.port}`;
    return `${baseUrl}`;
  }

  private async callXmlRpcMethod<T>(methodCallXml: string): Promise<FlrigResponse<T>> {
    const xmlPayload = `
      <?xml version="1.0"?>
      <methodCall>
        ${methodCallXml}
      </methodCall>
    `;

    try {
      const responseText = await this.makeRequest(xmlPayload);
      return this.parseXmlResponse<T>(responseText);
    } catch (error) {
      rigControlLogger("Error calling XML-RPC method:", error);
      return {
        success: false,
        error: error instanceof Error ? error.message : "Unknown error occurred",
        errorCode: 'CONNECTION_ERROR'
      };
    }
  }

  private async makeRequest(xmlPayload: string): Promise<string> {
    const url = this.buildUrl();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "text/xml",
      },
      body: xmlPayload,
      signal: AbortSignal.timeout(5000),
    });

    if (!response.ok) {
      if (response.status === 500) {
        throw new Error("Server error! Ensure Flrig is running and verify host/port configuration.");
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.text();
  }

  private parseXmlResponse<T>(xmlText: string): FlrigResponse<T> {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, "application/xml");

    // Handle Faults
    const faultNode = xmlDoc.querySelector("fault");
    if (faultNode) {
      const faultCode = xmlDoc.querySelector("fault value struct member value int")?.textContent;
      const faultString = xmlDoc.querySelector("fault value struct member value string")?.textContent;
      return {
        success: false,
        error: `Fault: Code ${faultCode}, ${faultString}`,
        errorCode: 'PARSE_ERROR'
      };
    }

    // Handle Empty Values
    const valueNode = xmlDoc.querySelector("params > param > value");
    if (!valueNode || !valueNode.textContent) {
      return {
        success: true,
        value: null as unknown as T
      };
    }

    // Handle array responses (like for getModes)
    const arrayNode = valueNode.querySelector("array > data");
    if (arrayNode) {
      const values = Array.from(arrayNode.querySelectorAll("value"))
        .map(node => node.textContent)
        .filter((text): text is string => text !== null);
      return {
        success: true,
        value: values as unknown as T
      };
    }

    return {
      success: true,
      value: valueNode.textContent as unknown as T
    };
  }

  public async getRadio(): Promise<FlrigResponse<string>> {
    const payload = `
      <methodName>rig.get_xcvr</methodName>
    `;

    try {
      const response = await this.callXmlRpcMethod<string>(payload);

      if (!response.success) {
        return {
          success: false,
          error: response.error,
          errorCode: 'CONNECTION_ERROR'
        };
      }

      if (!response.value) {
        return {
          success: false,
          error: "No response from radio",
          errorCode: 'NO_RADIO'
        };
      }

      if (response.value === "NONE") {
        return {
          success: false,
          error: "No radio connected",
          errorCode: 'NO_RADIO'
        };
      }

      return {
        success: true,
        value: response.value
      };
    } catch (error) {
      return {
        success: false,
        error: error instanceof Error ? error.message : "Unknown error",
        errorCode: 'CONNECTION_ERROR'
      };
    }
  }

  public async getFrequency(): Promise<FlrigResponse<number>> {
    const payload = `
      <methodName>rig.get_vfo</methodName>
    `;

    try {
      const response = await this.callXmlRpcMethod<string>(payload);

      if (!response.success) {
        return {
          success: false,
          error: response.error,
          errorCode: 'CONNECTION_ERROR'
        };
      }

      const frequency = Number(response.value);

      if (isNaN(frequency)) {
        return {
          success: false,
          error: "Invalid frequency value received",
          errorCode: 'INVALID_VALUE'
        };
      }
      this.currentState.frequency = frequency;
      return {
        success: true,
        value: frequency
      };
    } catch (error) {
      return {
        success: false,
        error: error instanceof Error ? error.message : "Unknown error",
        errorCode: 'CONNECTION_ERROR'
      };
    }
  }

  public async setFrequency(vfoA: number): Promise<FlrigResponse<void>> {
    const payload = `
      <methodName>rig.set_vfo</methodName>
      <params>
        <param>
          <value><double>${vfoA}</double></value>
        </param>
      </params>
    `;
    this.currentState.frequency = vfoA;
    return await this.callXmlRpcMethod<void>(payload);
  }



  // MODE
  public async getAvailableModes(): Promise<FlrigResponse<string[]>> {
    const payload = `
      <methodName>rig.get_modes</methodName>
    `;
    const response = await this.callXmlRpcMethod<string[]>(payload);
    if (response.success && response.value) {
      rigControlLogger("Available Modes:", response.value);
      this.availableModes = response.value as FlrigModes[];
    }
    return response;
  }
  public async getMode(): Promise<FlrigResponse<FlrigModes>> {
    const payload = `
      <methodName>rig.get_mode</methodName>
    `;
    const response = await this.callXmlRpcMethod<FlrigModes>(payload);
    if (response.success) {
      this.currentState.mode = response.value;
    }
    return response;
  }
  public async setMode(mode: CommonModes, band?: BandType): Promise<FlrigResponse<FlrigModes | void>> {
    if (!band && ['SSB'].includes(mode)) {
      return {
        success: false,
        error: "Band is required to set mode",
        errorCode: 'MISSING_ARGUMENTS'
      };
    }
    if (!this.availableModes.length) {
      const modesResponse = await this.getAvailableModes();
      if (!modesResponse.success) {
        return {
          success: false,
          error: "Failed to get modes",
          errorCode: modesResponse.errorCode
        };
      }
    }
    // If we are already on the 'right' mode for FT8, don't change it
    if (['FT8', 'FT4'].includes(mode) && this.currentState.mode && ['USB-D1', 'USB-D2', 'USB-D3', 'DATA-U', 'USB-D'].includes(this.currentState.mode)) {
      return {
        success: true,
        value: this.currentState.mode
      };
    }

    const rigMode = this.mapCommonModeToAvailableModes(mode, this.availableModes, band);

    const payload = `
      <methodName>rig.set_mode</methodName>
      <params>
        <param>
          <value>${rigMode}</value>
        </param>
      </params>
    `;

    const response = await this.callXmlRpcMethod<void>(payload);
    if (response.success) {
      console.log('success!', response, rigMode)
      this.currentState.mode = rigMode;
    }
    return response
  }

  public async getPower(): Promise<FlrigResponse<number>> {
    const payload = `
      <methodName>rig.get_power</methodName>
    `;
    try {
      const response = await this.callXmlRpcMethod<string>(payload);

      if (!response.success) {
        return {
          success: false,
          error: response.error,
          errorCode: 'CONNECTION_ERROR'
        };
      }

      if (!response.value) {
        return {
          success: false,
          error: "No power value received",
          errorCode: 'INVALID_VALUE'
        };
      }

      const power = Number(response.value);
      if (Number.isNaN(power) || power < 0) {
        return {
          success: false,
          error: "Invalid power value received",
          errorCode: 'INVALID_VALUE'
        };
      }
      this.currentState.power = power;
      return {
        success: true,
        value: power
      };
    } catch (error) {
      return {
        success: false,
        error: error instanceof Error ? error.message : "Unknown error",
        errorCode: 'CONNECTION_ERROR'
      };
    }
  }

  public async testConnection(): Promise<FlrigResponse<boolean>> {
    rigControlLogger(`Testing ${this.host}:${this.port} connection...`);

    const radioResponse = await this.getRadio();

    if (radioResponse.errorCode === 'NO_RADIO') {
      rigControlLogger(`No Radio Detected`);
      return {
        success: false,
        error: "No radio detected",
        errorCode: 'NO_RADIO'
      };
    }

    if (!radioResponse.success) {
      rigControlLogger(`Connection failed: ${radioResponse.error}`);
      return {
        success: false,
        error: radioResponse.error || "Connection failed",
        errorCode: radioResponse.errorCode
      };
    }

    const modesResponse = await this.getAvailableModes();
    if (!modesResponse.success) {
      return {
        success: false,
        error: "Connected to radio but failed to get modes",
        errorCode: modesResponse.errorCode
      };
    }

    return {
      success: true,
      value: true
    };
  }
}

export default FlrigService;