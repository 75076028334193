<script setup lang="ts">
  import { type PropType, computed } from 'vue';
  import type { SotaSummitType } from '@/types';

  const props = defineProps({
    summit: {
      type: Object as PropType<SotaSummitType>,
      required: true
    }
  });
  const location = computed(() => {
    return `${props.summit.country}, ${props.summit.region}`
  });
</script>

<template>
  <div data-testid="toast">
    <div data-testid="toast__header" class="font-bold text-sm mb-1 flex">
      <span data-testid="toast__badge"
        class="inline-flex items-center text-emerald-600 rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-bold uppercase">
        SOTA
      </span>
      <span data-testid="toast__title" class="ml-2 text-base font-mono">
        {{ props.summit.id }}
      </span>
    </div>
    <div data-testid="toast__body">
      <div class="font-semibold titlecase text-sm">{{ props.summit.name }} </div>
      <div class="font-medium titlecase text-sm mt-1 font-mono">
        <i class="fa-solid fa-earth-americas"></i> {{ location }}
        <i class="fa-sharp-duotone fa-solid fa-location-crosshairs ml-2"></i> {{ props.summit.grid }}
      </div>
    </div>
  </div>
</template>
