// src/services/PotaParkJsonRepo.ts
import type { PotaParkType } from '@/types';

declare global {
  interface Window {
    parksData: PotaParkType[];
  }
}

const PotaParkJsonRepo = {
  fetchById(id: string): PotaParkType {
    const normalizedId = id.toUpperCase();
    const parks = window.parksData;
    if (!parks) {
      throw new Error('Parks data is not loaded.');
    }
    const park = parks.find((park) => park.id === normalizedId);
    if (!park) {
      throw new Error(`Park with ID ${id} not found.`);
    }
    return park;
  },
  lastUpdatedAt(): number {
    const parks = window.parksData;
    if (!parks) {
      throw new Error('Parks data is not loaded.');
    }
    return Math.max(...parks.map((park) => park.updatedAt));
  },
};

export default PotaParkJsonRepo;