import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import type { RemovableRef } from "@vueuse/core";
import FlrigService, { type FlrigResponse, mapFlrigModeToCommonMode } from "@/services/FlrigService";
import { rigControlLogger } from "@/utils/loggers";
import type { BandType, FlrigModes, CommonModes } from "@/types";

interface RigControlState {
  enabled: RemovableRef<boolean>;
  flrigConnectionTested: boolean;
  flrigConnectionSuccess: boolean;
  flrigConnectionError: string;
  flrigHost: RemovableRef<string>;
  flrigPort: RemovableRef<string>;
  flrigService: FlrigService | null;
  availableModes: FlrigModes[];
  power: number;
  mode: string;
  frequency: number;
  heartbeat: ReturnType<typeof setInterval> | null;
}

export const useRigControlStore = defineStore("rigControl", {
  state: (): RigControlState => ({
    enabled: useLocalStorage("pinia/settings/flrigEnabled", false),
    flrigConnectionTested: false,
    flrigConnectionSuccess: false,
    flrigConnectionError: "",
    flrigHost: useLocalStorage("pinia/settings/flrigHost", "localhost"),
    flrigPort: useLocalStorage("pinia/settings/flrigPort", "12345"),
    flrigService: null,
    availableModes: [],
    power: 0,
    mode: "",
    frequency: 0,
    heartbeat: null,
  }),

  getters: {
    rigControlAvailable: (state): boolean => {
      return state.flrigConnectionTested &&
        state.flrigConnectionSuccess &&
        state.flrigService !== null;
    },
    rigControlEnabled: (state): boolean => {
      return state.enabled && state.flrigConnectionSuccess;
    }
  },

  actions: {
    /**
     * Handles response from FlrigService and updates store state
     */
    handleFlrigResponse<T>(
      response: FlrigResponse<T>,
      successCallback?: (value: T) => void,
      errorMessage: string = "Operation failed"
    ) {
      if (response.success && response.value !== undefined) {
        this.flrigConnectionError = "";
        successCallback?.(response.value);
        return true;
      } else {
        if (response.errorCode === 'NO_RADIO') {
          this.stopHeartbeat();
        }
        this.flrigConnectionError = response.error || errorMessage;
        return false;
      }
    },

    startHeartbeat() {
      rigControlLogger("💚 Starting Heartbeat...");
      if (this.heartbeat) return;
      this.pollRadio();
      this.heartbeat = setInterval(() => this.pollRadio(), 5000);
    },

    stopHeartbeat() {
      if (this.heartbeat) {
        rigControlLogger("❤️ Stopping Heartbeat...");
        clearInterval(this.heartbeat);
        this.heartbeat = null;
        this.enabled = false;
        this.flrigConnectionSuccess = false;
        this.flrigConnectionTested = false;
        this.flrigService = null;
      }
    },

    async pollRadio() {
      this.initializeService();
      if (!this.rigControlEnabled || !this.flrigService) {
        this.stopHeartbeat();
        return;
      }

      try {
        const frequencyResponse = await this.getFrequency();
        const modeResponse = await this.getMode();
        const powerResponse = await this.getPower();

        if (!frequencyResponse || !modeResponse || !powerResponse) {
          this.stopHeartbeat();
        }
      } catch (error) {
        rigControlLogger("Poll Error:", error);
        this.stopHeartbeat();
      }
    },

    initializeService() {
      if (!this.flrigService) {
        this.flrigService = new FlrigService(this.flrigHost, this.flrigPort);
      }
    },

    async setFrequency(freq: number) {
      this.initializeService();
      if (!this.flrigService) return;

      const response = await this.flrigService.setFrequency(freq);
      return this.handleFlrigResponse(
        response,
        () => this.frequency = freq,
        "Failed to set frequency"
      );
    },

    async getFrequency() {
      if (!this.flrigService) return false;

      const response = await this.flrigService.getFrequency();
      return this.handleFlrigResponse(
        response,
        (value) => this.frequency = value,
        "Failed to get frequency"
      );
    },

    async getMode() {
      if (!this.flrigService) return false;

      const response = await this.flrigService.getMode();
      return this.handleFlrigResponse(
        response,
        (value) => this.mode = mapFlrigModeToCommonMode(value),
        "Failed to get mode"
      );
    },

    async getPower() {
      if (!this.flrigService) return false;

      const response = await this.flrigService.getPower();
      return this.handleFlrigResponse(
        response,
        (value) => this.power = value,
        "Failed to get power"
      );
    },

    async setMode(mode: CommonModes, band?: BandType) {
      this.initializeService();
      if (!this.flrigService) return;

      const response = await this.flrigService.setMode(mode, band);
      return this.handleFlrigResponse(
        response,
        () => this.mode = mapFlrigModeToCommonMode(mode),  // Use the input mode directly
        "Failed to set mode"
      );
    },

    async testConnection() {
      rigControlLogger("Testing connection to Flrig");
      this.initializeService();

      try {
        const response = await this.flrigService!.testConnection();

        this.flrigConnectionTested = true;
        this.flrigConnectionSuccess = response.success;
        this.enabled = response.success;

        if (response.success) {
          this.availableModes = this.flrigService!.availableModes;
          this.flrigConnectionError = "";
          this.startHeartbeat();
        } else {
          this.flrigConnectionError = response.error ||
            "Unable to connect to Flrig. Check host/port.";
          this.stopHeartbeat();
        }

        return response;
      } catch (error) {
        this.enabled = false;
        this.flrigConnectionTested = true;
        this.flrigConnectionSuccess = false;
        this.flrigConnectionError = "An unexpected error occurred while testing the connection.";
        rigControlLogger("testConnection Error:", error);
        this.stopHeartbeat();
        return {
          success: false,
          error: "An unexpected error occurred",
          errorCode: 'CONNECTION_ERROR'
        };
      }
    },

    async toggleRigControl() {
      if (this.enabled) {
        this.enabled = false;
        this.stopHeartbeat();
      } else {
        await this.testConnection();
      }
    },

    updateHostAndPort(host: string, port: string) {
      this.flrigHost = host;
      this.flrigPort = port;
      this.flrigService = null;
    },
  },
});