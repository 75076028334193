import { computed } from 'vue';
import { useRigControlStore } from '@/stores/RigControlStore';
import type { BandType, CommonModes } from '@/types';
import isElectron from 'is-electron';

export function useRigControl() {
  const isDev = process.env.NODE_ENV === 'development';
  const rigControlStore = useRigControlStore();

  const isRigControlCapable = computed(() => isDev || isElectron());
  const isRigControlEnabled = computed(() => rigControlStore.enabled);
  const isRigControlAvailable = computed(() => isRigControlCapable.value && rigControlStore.rigControlAvailable);

  const testConnection = () => rigControlStore.testConnection();

  const toggleRigControl = () => rigControlStore.toggleRigControl();

  const setFrequency = (frequency: number) => rigControlStore.setFrequency(frequency);
  const frequency = computed(() => rigControlStore.frequency);

  const setMode = (mode: CommonModes, band: BandType) => {
    return rigControlStore.setMode(mode, band);
  };


  return {
    isRigControlAvailable,
    isRigControlEnabled,
    isRigControlCapable,
    setFrequency,
    frequency,
    setMode,
    testConnection,
    toggleRigControl,
  };
}