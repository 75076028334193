type LogContext =
  | "HAMRS_CLIENT"
  | "HAMRS_API"
  | "LOCATION"
  | "PROFILES"
  | "LOOKUPS"
  | "LOGGER"
  | "POTA_PARKS"
  | "POUCH"
  | "SOTA"
  | "RIG_CONTROL";

export const LOG_EMOJIS: Record<LogContext, string> = {
  HAMRS_CLIENT: "📱",
  HAMRS_API: "📡",
  PROFILES: "👤",
  LOOKUPS: "🔍",
  LOGGER: "🪵",
  LOCATION: "📍",
  POTA_PARKS: "🏕️",
  SOTA: "🏔️",
  POUCH: "🗄️",
  RIG_CONTROL: "🎮",
};

function createLogger(
  context: LogContext,
  emoji: string
): (...args: any[]) => void {
  return function (...args: any[]) {
    console.log(emoji, `[${context.replace("_", " ")}]`, ...args);
  };
}

export const profilesLogger = createLogger("PROFILES", LOG_EMOJIS.PROFILES);
export const lookupsLogger = createLogger("LOOKUPS", LOG_EMOJIS.LOOKUPS);
export const hamrsClientLogger = createLogger(
  "HAMRS_CLIENT",
  LOG_EMOJIS.HAMRS_CLIENT
);
export const hamrsApiLogger = createLogger("HAMRS_API", LOG_EMOJIS.HAMRS_API);
export const loggerLogger = createLogger("LOGGER", LOG_EMOJIS.LOGGER);
export const locationLogger = createLogger("LOCATION", LOG_EMOJIS.LOCATION);
export const potaParksLogger = createLogger("POTA_PARKS", LOG_EMOJIS.POTA_PARKS);
export const pouchLogger = createLogger("POUCH", LOG_EMOJIS.POUCH);
export const sotaLogger = createLogger("SOTA", LOG_EMOJIS.SOTA);
export const rigControlLogger = createLogger("RIG_CONTROL", LOG_EMOJIS.RIG_CONTROL);
