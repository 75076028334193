import { defineStore } from "pinia"
import { useLocalStorage } from '@vueuse/core'
import QrzApiClient from "@cabin-interactive/qrz-api-client"
import { version } from '../../package.json'

export const useQrzApiIntegrationStore = defineStore("qrzApiIntegration", {
  state: () => ({
    apiKey: useLocalStorage("pinia/integrations/qrz/apiKey", ""),
    apiKeyTested: useLocalStorage("pinia/integrations/qrz/apiKeyTested", false),
    apiKeyValid: useLocalStorage("pinia/integrations/qrz/apiKeyValid", false),
  }),
  actions: {
    createClient() {
      if (!this.apiKey?.trim()) {
        return null
      }
      try {
        return new QrzApiClient({
          apiKey: this.apiKey,
          userAgent: `HAMRS/${version}`,
          proxyUrl: 'https://qrz-api-proxy.onrender.com'
        })
      } catch (error) {
        console.error('Failed to create QRZ API client:', error)
        return null
      }
    },
    async testApiKey() {
      const client = this.createClient()
      if (!client) { return }
      try {
        const response = await client.testAuth()
        this.apiKeyValid = response.isValid
        this.apiKeyTested = true
      } catch (error) {
        this.apiKeyValid = false
        this.apiKeyTested = true
      }
    },
  },
})