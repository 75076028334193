
const HAMRS_API_URL = 'https://api.hamrs.app/v1';
import type { PotaParkType, PotaSpotType } from "@/types";

const HamrsApiService = {

  async fetchPark(parkId: string): Promise<PotaParkType> {
    console.log('fetching park from api', parkId)
    const response = await fetch(`${HAMRS_API_URL}/pota/parks/${parkId}`);
    if (!response.ok) {
      throw new Error(
        `An error occurred while fetching the park: ${response.status} ${response.statusText}`
      );
    }
    const data = await response.json();
    return data;
  },
  async fetchParks(since: number = 0): Promise<PotaParkType[]> {
    const response = await fetch(`${HAMRS_API_URL}/pota/parks?updated_since=${since}`);
    const data = await response.json();
    return data;
  },
  async fetchSpots(): Promise<PotaSpotType[]> {
    const response = await fetch(`${HAMRS_API_URL}/pota/spots`);
    if (!response.ok) {
      throw new Error(
        `An error occurred while fetching spots: ${response.status} ${response.statusText}`
      );
    }
    const data = await response.json();
    return data;
  }
}

export default HamrsApiService